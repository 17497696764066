.btn-group {
  position: relative;
  display: inline-flex;
  vertical-align: middle; // match .btn alignment given font-size hack above

  > .btn {
    position: relative;
    flex: 0 1 auto;

    // Bring the hover, focused, and "active" buttons to the front to overlay
    // the borders properly
    &:hover {
      z-index: 1;
    }

    &:focus,
    &:active,
    &.active {
      z-index: 1;
    }
  }

  // Prevent double borders when buttons are next to each other
  .btn + .btn,
  .btn + .btn-group,
  .btn-group + .btn,
  .btn-group + .btn-group {
    margin-left: -$btn-border-width;
  }
}

.btn-group {
  > .btn:first-child {
    margin-left: 0;
  }

  // Reset rounded corners
  > .btn:not(:last-child):not(.dropdown-toggle),
  > .btn-group:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  > .btn:not(:first-child),
  > .btn-group:not(:first-child) > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}


//
// Split button dropdowns
//

.dropdown-toggle-split {
  padding-right: $btn-padding-x * .5;
  padding-left: $btn-padding-x * .5;

  &::after,
  .dropup &::after,
  .dropright &::after {
    margin-left: 0;
  }

  .dropleft &::before {
    margin-right: 0;
  }
}

.btn--sm + .dropdown-toggle-split {
  padding-right: $btn-padding-x-sm * .75;
  padding-left: $btn-padding-x-sm * .75;
}

.btn--lg + .dropdown-toggle-split {
  padding-right: $btn-padding-x-lg * .75;
  padding-left: $btn-padding-x-lg * .75;
}

.btn-group.show .dropdown-toggle {
  box-shadow: $btn-active-box-shadow;

  // Show no shadow for `.btn-link` since it has no other button styles.
  &.btn-link {
    box-shadow: none;
  }
}
