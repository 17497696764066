// icon with circle border
.fa.border--circle {
  @include square-size(1rem);

  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  font-size: .625rem;
}

.fa.border--circle--lg {
  @include square-size(2rem);

  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  font-size: 1.2rem;
}

// for FA icons on the left of a dropdown item
.dropdown-item > .fa {
  width: 1.25rem;
}

.fill-in-the-blank-question .fa-question {
  text-align: center;
  border-bottom: solid black .12em;
}

[data-question-kind='fill_in_the_blank_question'] .fa-question {
  border-bottom: solid white .12em;
}
