@mixin grad-box {
  border-radius: 4px;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(237, 237, 237, 1) 100%);
  border: 1px solid $med-grey;
  position: relative;
}

/* (some) invalid fields and error messages */
input[type="text"]:disabled {
  background-color: #ebebe4;
}

input[type="text"]:read-only {
  background-color: #ebebe4;
}

input[type='email']:read-only {
  background-color: #eaeae4;
}

textarea {
  &.invalid_field {
    border: 1px solid #f00;
  }
}

input {
  font-family: $font-family-base;

  &.invalid_field, &:invalid {
    border-color: #f00;
  }

  &.chosen-focus-input {
    display: none;
  }
}

select {
  font-family: $font-family-base;
  font-size: $font-size-base;
}


// needed to allow the use of the required attribute
// https://github.com/harvesthq/chosen/issues/2075
.chosen-select:invalid {
  height: 0 !important;
  opacity: 0 !important;
  position: absolute !important;
  display: flex !important;
}

.chosen-container {
  &.no-results-text {
    .no-results {
      span {
        visibility: hidden;
      }
    }
  }
}

.chosen-select:invalid[multiple] {
  margin-top: 15px !important;
}

p.invalid_field_error {
  color: #f00;

  &.inline_editor_field {
    position: absolute;
    bottom: -22px;
    font-size: 14px;
    left: 0;
  }

  &.model-resources {
    margin-left: 110px;
  }
}

input.string,
input.password,
input.numeric,
input.textinput,
textarea.text {
  color: #222;
  font-size: 93%;
  padding: 4px;
  width: 220px;
  border: 1px solid #aaa;
  border-radius: 4px;
}

input.small {
  padding: 3px;
  border: 2px solid $med-grey;
  width: 60px;
}

label {
  display: inline-block;
  &.multi_line { padding-top: 0 !important; }
}

.bold_labels label {
  font-weight: $font-weight-bold;
}


/* Horizontal Form used on student search */
.hform {
  background: none repeat scroll 0 0 $light-grey;
  margin-bottom: 9px;
  overflow: hidden;
  padding: 5px 0 5px 12px;

  .input_submit {
    margin-left: 10px;
  }
}

.hform label {
  font-size: 12px;
  line-height: 1.5em;
  position: relative;
  top: 4px;
}

.hform input.text_input {
  border: 1px solid #aaa;
  border-radius: 4px;
  color: #222;
  font-size: 93%;
  padding: 3px;
  margin-left: 4px;
  margin-right: 12px;
}

.hform input.short_input {
  width: 20px;
}

.hform input.name {
  width: 100px;
}

.hform input.email {
  width: 180px;
}

.hform input,
.hform label {
  float: left;
}

.hform td {
  vertical-align: middle;
}

.form_narrow label {
  display: block;
  float: none;
  padding: 4px 0;
  width: auto;
  font-size: 12px;
  font-weight: $font-weight-bold;
}

.input_400 input {
  width: 400px;
}

.input_numeric input {
  &#classroom_period { width: 100px; }
  width: 40px;
}

div.radio_options {
  margin: 15px 0;

  .option {
    display: block;
    border-bottom: 1px dotted $med-grey;
    margin: 10px;

    input {
      margin-bottom: 10px;
    }
  }
}

.form_wide label {
  display: block;
  width: 970px !important;
  font-size: 14px !important;
  margin-bottom: 5px;
}

.form_wide label span {
  font-weight: normal;
  padding-left: 10px;
}


div.input {
  padding: 4px 0;
  overflow: hidden;
  margin-bottom: 5px;

  &.has-tooltip, &.select { overflow: inherit; }

  > label.boolean {
    width: auto;
    position: relative;
    top: -4px;
  }

  select.inline { margin-left: 10px; }

  input[type="checkbox"] { float: left; }
}

.simple_form fieldset legend,
div.input > label {
  color: $very-dark-grey;
  display: block;
  float: left;
  font-weight: $font-weight-bold;
  padding-top: 5px;
  width: 140px;
}

.transfer_sub {
  padding-top: 5px;
  display: flex;
}

div.input.text { height: auto; }

div.input > span.error {
  color: red;
  display: block;
  margin-left: 120px;
  padding: 2px 0;
  font-size: 12px;
}

.large_text_area textarea {
  margin: 20px auto;
  padding: 10px;
  width: 830px;
  display: block;
  height: 350px;
}

.form_wide textarea {
  width: 900px;
  display: block;
  height: 100px;
}

.label {
  color: $very-dark-grey;
  float: left;
  font-weight: $font-weight-bold;
  padding-top: 5px;
  display: inline-block;
}

input.submit_button,
a.submit_button {
  border-radius: 4px;
  box-shadow: 1px 1px 5px #999;
  background-color: #333;
  background-repeat: repeat-x;
  border: 1px solid #aaa;
  color: $white;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  margin-top: 10px;
  padding: 2px 10px 4px;
  position: relative;
  text-decoration: none;
  text-shadow: 0 1px 3px $black;
  font-size: 15px;
}

a.submit_button {
  padding-top: 6px;
  padding-bottom: 6px;
}

.purple.submit_button {
  background-color: $tci-purple;
  border-color: $tci-purple;
  color: $white;

  &:disabled {
    background-color: #9b8199;
    cursor: not-allowed;
  }

  &:not(:disabled):hover {
    background-color: #673bb5;
    color: $white;
  }
}

.green.submit_button {
  background: linear-gradient(to bottom, rgba(176, 176, 40, 1) 0%, rgba(122, 159, 51, 1) 100%);
  border-color: #8fa85f;
}

.green.submit_button:hover {
  background: linear-gradient(to bottom, rgba(204, 204, 6, 1) 0%, rgba(131, 185, 23, 1) 100%);
}

a.cancel_button {
  color: $link-purple;
  cursor: pointer;
  display: inline-block;
  font-size: 15px;
  line-height: 15px;
  margin-top: 10px;
  padding: 3px 10px 3px;
  position: relative;
  text-decoration: none;
  top: 7px;
}

@-moz-document url-prefix() {
  a.cancel_button {
    line-height: 21px;
    top: 6px;
  }
}

a.cancel_button:hover { background-color: $med-grey; }

div.input > span.hint {
  display: block;
  padding-left: 120px;
  font-size: 11px;
  padding-top: 5px;
}

.wide_page div.input > span.hint { padding-left: 0; }

.form_table {
  td {
    min-width: 200px;
    padding-left: 20px;
  }
}

div.form_container {
  @include grad-box;
  margin: 0 auto;
  padding: 20px;
  width: 370px;

  h1 {
    margin-top: 0;
    padding-bottom: 16px;
  }

  label { line-height: 1.3em; }

  h2 {
    border-bottom: 1px solid $med-grey;
    color: $very-dark-grey;
    font-size: 160%;
    margin-bottom: 20px;
    padding-bottom: 8px;
    font-weight: $font-weight-bold;
  }

  div.form_messaging {
    margin-bottom: 10px;
    border-bottom: 1px solid $med-grey;

    h1 {
      margin-bottom: 5px;
      font-size: 130%;
      border-bottom: 1px solid $med-grey;
      color: $very-dark-grey;
      padding-bottom: 8px;
      font-weight: $font-weight-bold;
    }

    p { line-height: 1.5em; }
  }

  .link_options {
    width: 170px;
    height: 50px;
    border: 1px solid #649022;
    border-radius: 3px;
    background-color: #99a731;
    text-align: center;
    text-transform: uppercase;
    color: $white;
    box-shadow: inset 1px 1px 1px 0 #a9b439;
    cursor: pointer;
    font-size: 14px;
    line-height: 50px;

    &#link_school_div {
      margin: 15px auto;
      font-size: 17px;
    }
  }

  .dash_list {
    padding-left: 10px;
    text-indent: -10px;
  }

  &.parent_order_form {
    font-size: 16px;
    line-height: 20px;
    color: #666;
  }

  #link_homeschool_div {
    width: 150px;
    text-align: center;
    margin: 30px auto;
    color: $tci-purple;
    text-decoration: underline;
    cursor: pointer;
  }

  .link_options:hover {
    background-color: #a3b234;
  }

  .row {
    @include display(flex);
    @include justify-content(space-between);
    @include align-items(center);

    width: 100%;
  }
}

div.tool_tips_box {
  position: absolute;
  width: 285px;
  background-color: $white;
  border: 1px solid #aaa;
  font-size: 12px;
  padding: 5px;
  line-height: 17px;
  box-shadow: 0 0 10px #dadada;
  z-index: 2;

  &.sign_in_tips {
    top: 130px;
    left: 395px;
  }

  &.forgotten_password {
    bottom: 30px;
    left: 205px;
    display: none;
  }

  ul {
    margin: 10px 0;
    list-style-type: disc;
    list-style-position: inside;

    li { margin: 5px 0; }
  }
}

#add_teacher .form_container,
#add_student_directly .form_container {
  border: none;
  background: transparent;
  border-radius: 0;
  box-shadow: none;
  margin: 0;
}

div.form_container.left { margin: 0; }

div.form_container .account {
  color: $very-dark-grey;
  font-size: 12px;
  font-weight: $font-weight-bold;
  padding: 12px 0;
}

div.form_container p.sign_up_code { line-height: 17px; }

div.form_container span.label {
  color: $very-dark-grey;
  display: block;
  float: left;
  width: 120px;
}

div.form_container span.note {
  font-weight: normal;
  font-size: 13px;
  float: none;
}

.form_sideways form { overflow: hidden; }
.sideways { float: left; }
.sideways + .sideways { margin-left: 30px; }

table.multiple_choice {
  td {
    padding: 5px 0;
    vertical-align: middle;
    input.string { width: 400px; }
    label {
      display: flex;
      align-items: center;
    }
  }
}

.image_choices_container {
  .grid_header {
    display: flex;

    p {
      margin: 0;
    }
  }

  .image_choices_grid {
    width: 96%;
    margin: 10px auto 20px;
    display: grid;
    grid-template-columns: 310px 310px;
    grid-row: auto;
    grid-column-gap: 108px;
    grid-row-gap: 20px;

    .grouped & {
      grid-column-gap: 66px;
    }
  }

  .header_text {
    margin-left: 20px;
  }

  .image_choice {
    display: flex;
    flex-direction: column;
    width: 310px;
    height: 375px;
    padding: 5px;
    align-items: center;
    position: relative;

    .image_choice_card_body {
      width: 100%;
      height: calc(100% - 55px);
      overflow: hidden;
    }

    .image_choice_card_footer {
      display: flex;
      width: 100%;
      height: 55px;
      position: absolute;
      bottom: 0;
      left: 0;

      .checkbox_container {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: 15px;

        .correct_choice_checkbox {
          margin-top: 1px;
          margin-right: 5px;
        }
      }
    }

    hr {
      border: 0;
      margin: 0;
      width: 103%;
      background-color: #e4e4e4;
    }
  }
}

input.short {
  width: 20px;
}

#teacher_invite {
  margin-top: 15px;
  margin-right: 20px;
}

div.input.check_boxes {
  height: auto;
  overflow: visible;
}

div.input.check_boxes ul {
  display: block;
  clear: both;
}

div.input.check_boxes ul li {
  padding: 3px 0;
}

input.password_field {
  background-image: image-url('input_password_background.png');
  background-repeat: no-repeat;
  background-position: 0 center;
}

#caps_warning {
  border: 1px solid #ddd;
  padding: 2px 0 2px 5px;
  margin: -5px 0 10px 229px;
  width: 114px;
  border-radius: 5px;
  color: #444;
  display: none;
  font-size: 12px;
  font-weight: $font-weight-bold;
  line-height: 22px;
  font-variant: small-caps;
  img { vertical-align: middle; }
}

#switch-account {
  margin-top: -4px;
  padding-left: 121px;
}

#student-switch-account {
  padding-left: 121px;
  padding-bottom: 5px;
  margin-top: -5px;
}

#invalid_po {
  line-height: 20px;
  width: 350px;
}

.bold_italic {
  font-style: italic;
  font-weight: $font-weight-bold;
}

.centered_text {
  text-align: center;
}

.centered_el {
  margin: 0 auto;
  text-align: center;
}

.button_padding {
  padding: 2px 10px 4px !important;
  margin-top: 0 !important;
}

#new_account_box {
  border-top: 1px solid $med-grey;
  margin-top: 10px;
  padding: 15px 0 0;
  line-height: 20px;
}

label.label_style {
  padding-top: 8px;
  display: inline;
  color: $very-dark-grey;
}

input#disabled_submit {
  background-color: #9b8199;
  cursor: not-allowed;
}

div.tags-container > div {
  overflow: visible;
  min-height: 30px;
  &.cets { min-height: 55px; }
}

.form_narrow {
  div.input {
    &.boolean { height: 30px; }
    &.text { height: auto; }

    > label {
      display: block;
      float: none;
      padding: 4px 0;
      width: auto;
      font-size: 12px;
    }

    > span.error { margin-left: 0; }
  }

  textarea {
    height: 150px;
    width: 400px;
  }

  &.wide_page textarea {
    height: 65px;
    width: 83%;
  }
}

#user_new div.input > span.error {
  color: red;
  display: block;
  margin-left: 0;
  padding: 2px 0;
  font-size: 12px;
  text-decoration: underline;
  cursor: pointer;
}

/* InlineEditor.js elements */
.inline-editor-container {
  @include display(flex);
  @include align-items(center);

  min-width: 320px;

  .inline-editor-input {
    height: 26px;
    border-radius: $border-radius;
    font-size: 18px;
    border: 1px solid $med-grey;
    box-shadow: none;
    width: 300px;
    display: none;
  }

  .inline-editor-btn {
    padding: 0;
    margin-left: 10px;
    display: none;
    font-size: 20px;
  }
}

.form-required-fields {
  label.required {
    &::after {
      content: ' *';
      color: $red;
    }
  }
}

.overflow-unset {
  & > div {
    overflow: unset;
  }
}

.custom-select {
  // Style resets for consistency
  background-color: transparent;
  border: 1px solid #aaa;
  border-radius: 4px;
  padding: 4px;
  margin: 0;
  width: 230px;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
}

input#staffer_email, input#staffer_password {
  background-color: #fff;
}
