.tci-banner {
  max-width: $site-width;
  margin: 20px 0;
  align-items: center;
  background: repeat scroll 0 0 #e9f8fe;
  padding: 3px 10px 3px;
  border: solid #247F99;
  border-width: thin;
  border-radius: 5px;

  p {
    background-repeat: no-repeat;
    padding-left: 32px;
    line-height: 15px;
    margin-bottom: 0;
    font-size: 15px;
    top: -13px;
    position: relative;
  }
}

.info-icon {
  position: relative;
  top: 7px;
  font-size: 25px;
  color: #247F99;
}

