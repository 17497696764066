.accessible-challenge-header {
  overflow: hidden;

  .score-label {
    font-weight: $font-weight-bold;
    color: $very-dark-grey;
  }

  #challenge_score {
    font-weight: normal;
  }
}

.accessible-challenge-questions {
  display: block;

  .accessible-challenge-question {
    padding: 20px 0 .5em 0;
  }

  .accessible-challenge-disabled {
    opacity: .5;
  }

  .accessible-question-content {
    padding: 0 30px;

    .accessible-answer-text {
      vertical-align: top;
      line-height: 18px;
      width: 90%;
      display: inline-block;
    }

    .accessible-challenge-image > img {
      max-width: 100%;
    }

    .accessible-challenge-footer {
      margin-top: 15px;
      display: flex;
      align-items: center;

      .accessible-challenge-submit {
        float: left;
        margin-right: 15px;
      }

      .accessible-challenge-notification {
        color: $black;
        float: left;
        font-weight: bold;
        padding: 10px;

        &.correct {
          color: #2a8806;
        }

        &.incorrect {
          color: #d2232a;
        }

        &.try-again {
          color: $black;
        }
      }
    }
  }
}

li.before-final-question {
  width: 100%;
  text-align: center;
}

.hide {
  display: none;
}
