#left_nav_bar {
  width: $left-nav-width;
  position: fixed;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: $zindex-left-nav;

  body.left-nav-collapsed & { width: $left-nav-collapsed-width; }

  &.light { background-color: $tci-purple; }
  &.dark { background-color: #262340; }

  .logo {
    display: block;
    height: 44px;
    background: {
      image: url("tci-logo-white.svg");
    }
    background-size: 45px;
    background-position: center;
    background-repeat: no-repeat;
  }
}

.site-banner {
  .logo {
    width: 250px;
  }
}
