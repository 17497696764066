// Tables
//
// Default styling with light grey borders and light grey header background
.table {
  width: 100%;
  max-width: 100%;
  background-color: $table-bg;
  border: $table-border-width solid $table-border-color;
  text-align: $table-text-align;

  th {
    color: $table-head-color;
    background-color: $table-head-bg;
    border-color: $table-border-color;
  }

  th,
  td {
    padding: $table-cell-padding;
    border-top: $table-border-width solid $table-border-color;
    line-height: $table-line-height;
  }

  td.no-top-border {
    border-top: 0;
    padding-top: 0;
  }

  thead th {
    font-weight: $font-weight-bold;
    border-bottom: (2 * $table-border-width) solid $table-border-color;
  }
}


//
// Small and large tables via padding
//
// stylelint-disable no-descending-specificity
.table--sm {
  th,
  td {
    padding: $table-cell-padding-sm;
  }
}
// stylelint-enable no-descending-specificity

.table--lg {
  td {
    padding: $table-cell-padding-lg;
  }
}

