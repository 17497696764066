/* Global styles */

// ---

.teacher_batchupload_table,
.teacher_batchupdate_table {
  border: 1px solid #bbb;
  padding: 1px;
  width: 585px;
  margin: 0 auto;
  background-color: #dedede;

  tr {
    padding-bottom: 4px;
  }

  col:first-child { background: #dedede; }

  td {
    border-right: 1px solid #f0f0f0;
    text-align: center;
    vertical-align: middle;
    width: 60px;
    padding: 7px;

    &:nth-child(1) {
      width: 440px;
      text-align: right;
      background-color: #f0f0f0;
    }

    &:nth-child(2) {
      width: 40px;
    }
  }

  tr:first-child {
    background-color: #f0f0f0;
    line-height: 28px;
  }
}

// ---

.materials_button {
  background-color: #503a90;
  margin-bottom: 0;
  box-shadow: inset 0 1px 1px 0 #9270d1;
  font-size: 14px;
  text-shadow: none;
  line-height: 18px;
  padding: 6px 10px 5px;
  margin-top: 0;
  color: $white;
  border-radius: 4px;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  border: 1px solid #2f2258;
  font-weight: normal;

  &:hover {
    background-color: #673bb5;
  }

  &.green {
    background-color: #2A8806;
    border-color: #64941e;
    box-shadow: inset 0 1px 1px 0 #b6c450;

    &:hover {
      background-color: #bcc809;
    }
  }
}

.disconnected_borders_table {
  width: 100%;

  + tr.grey { background-color: $light-grey; }

  td {
    padding: 0 10px;
    border-right: 1px solid #dedede;
    text-align: center;
    vertical-align: middle;

    span {
      font-size: 15px;
      color: #666;
    }

    &:last-child {
      border-right-style: none;
    }

    &:first-child {
      text-align: left;
    }

    img {
      max-width: 100px;
      max-height: 86px;
      padding: 0 5px;
    }
  }

  & > thead > tr > td {
    font-size: 17px;
    color: #333;
    line-height: 40px;
  }
}

/* *************** */

.tablebox {
  border: 1px solid #bbb;
  margin-bottom: 20px;
}

table.tabular_data {
  width: 100%;
  line-height: 1.3em;
}

table.tabular_data thead td {
  box-sizing: border-box;
}

table.tabular_data td table {
  width: 100%;

  td { border-left: none; }
}

table.tabular_data td.buttons {
  white-space: nowrap;
  text-align: center;
}

table.tabular_data td.name {
  width: 120px;
}

table.tabular_data td.email {
  width: 180px;
}

table.tabular_data.small_text tbody td {
  font-size: 12px;
}

table.tabular_data tr#possible_points {
  border-bottom: 1px solid #aaa;
}

table.tabular_data tr.program_return {
  background: #fedcd9 !important;
  td { background: #fedcd9 !important; }
}

table.tabular_data tr.transfer_row {
  background: #fffacd !important;
  td { background: #fffacd !important; }
}

table.tabular_data thead th,
table.tabular_data thead tr {
  background-color: $border-gray;
  font-weight: $font-weight-bold;
  vertical-align: middle;
  text-align: left;
  font-size: 13px;
  padding-top: 5px;
  padding-bottom: 5px;
}

table.tabular_data thead tr.sub td {
  background-image: image-url("bg-table-hdr-stripes.png");
  background-color: $border-gray;
  font-weight: $font-weight-bold;
  text-shadow: 1px 1px $light-grey;
}

table.tabular_data td {
  padding: 8px 10px;
  vertical-align: middle;
  font-size: 13px;
}

table.tabular_data td.actions {
  width: 200px;
  white-space: nowrap;
}

table.tabular_data td.center {
  text-align: center;
}

table.tabular_data td.lesson_name {
  font-weight: $font-weight-bold;
}

table.tabular_data td.student_name {
  width: 130px;
  display: block;
  white-space: nowrap;
}

table.tabular_data tr.average_score {
  border-top: 1px solid #aaa;
  background: $light-grey;
}

table.tabular_data td span.light {
  color: #999;
  font-size: 11px;
}

table.tabular_data tr:last-child {
  border-bottom: none;
}

table.tabular_data tr.even td {
  background-color: #f0f0f0;
}

table.tabular_data tbody td {
  border-left: 1px solid #dedede;
}

table.tabular_data#slide_shows .number {
  width: 4%;
}

table.tabular_data tbody tr td:first-child {
  border-left: none;
}

table.tabular_data tbody tr td.title {
  width: 35%;
}

table.tabular_data tbody tr.classroom_total td {
  border-bottom: 2px solid $dark-grey;
  padding: 4px 10px;
  background-color: #fafafa;
}

table.tabular_data tbody tr td a img {
  vertical-align: middle;
}

table.tabular_data tbody tr.selected td {
  background-color: $light-grey;
}

table.tabular_data tbody tr.selected td.name a {
  text-decoration: none;
  color: $very-dark-grey;
}

a.table_head_button:link,
a.table_head_button:visited {
  font-size: 12px;
  text-shadow: none;
  padding: 5px 8px 3px;
  border: 1px solid #aaa;
  text-decoration: none;
  background: #ddd;
  font-weight: normal;
  border-bottom-color: $light-grey;
  border-right-color: $light-grey;
  border-radius: 4px;
}

a.table_head_button:hover,
a.table_head_button:active {
  background-color: $link-purple;
  color: $white;
}

table.tabular_data tbody td ul li {
  line-height: 1.5em;
}

table.tabular_data td.border_left_center {
  border-left: 1px solid #aaa;
  text-align: center;
}

table.tabular_data.light {
  font-size: 11px;

  thead tr td, thead tr th {
    background: #f5f5f5;
    border: medium none;
    color: #666;
    font-size: 11px;
    padding: 5px 8px;
    font-weight: normal;
    border-bottom: 1px solid #ddd;
  }

  tr.even {
    background: $white;
  }

  td.name {
    font-weight: $font-weight-bold;
    font-size: 12px;
  }

  tbody td {
    border: none;
    border-bottom: 1px dotted $med-grey;
  }

  .actions a { font-size: 11px; }

  td.first_column {
    font-size: 12px;
    font-weight: $font-weight-bold;
  }
}

table.tabular_data.hover {
  tbody tr:hover {
    background: #f8f8f8;
  }
}

table.tabular_data.outline {
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;

  td {
    border-left: 1px solid #ddd !important;
    border-bottom: 1px solid #ddd !important;
    padding: 5px;
  }

  tbody tr td.title {
    width: 72%;
  }
}

table.tabular_data.align_top td {
  vertical-align: top;
}

.unit_sections_index {
  table.tabular_data.sections_list {
    th.actions {
      width: 10%;
    }
  }
}

table.tabular_data.sections_list {
  tbody tr:nth-child(even) {
    background-color: rgba(1, 1, 1, .05);
  }

  th.title {
    width: 35%;
  }

  th.view {
    width: 10%;
  }

  th.text {
    width: 10%;
  }

  th.tutorial {
    width: 15%;
  }

  th.reading_game {
    width: 15%
  }

  th.notebook {
    width: 10%;
  }

  th.action {
    width: 5%;
  }
}

table.form_table td {
  padding: 5px;
}

table.form_table td label {
  display: block;
}

table.form_table td select {
  width: 223px;
}

table td.email span {
  display: block;
  width: 180px;
  word-wrap: break-word;
}

table tr.grand_total {
  background: #dfdfff;
}

/* challenge tooltips */

.challenge_type span {
  background-position: 0 0;
  background-repeat: no-repeat;
  display: inline-block;
  height: 23px;
  margin: 0 auto;
  text-indent: -32767px;
  width: 23px;
}

.challenge_type span:hover {
  background-position: 0 -23px;
}

.challenge_type span.mok {
  background-image: image-url('icon_challenge/icon-mok.png');
}

.challenge_type span.pm {
  background-image: image-url('icon_challenge/icon-pm.png');
}

.challenge_type span.ctc {
  background-image: image-url('icon_challenge/icon-ctc.png');
}

.challenge_type span.cs {
  background-image: image-url('icon_challenge/icon-cs.png');
}

.challenge_type span.psi {
  background-image: image-url('icon_challenge/icon-psi.png');
}

/* end challenge tooltips */

table.separated {
  thead tr td {
    border-style: none !important;
  }

  tbody tr {
    border: none;
    border-bottom: 1px dotted $med-grey;
  }

  tbody tr:first-child {
    border-top: 1px solid $med-grey;
  }
}

/* Materials and Assessments  */
.materials_category {
  width: $site-width;
}

.materials_category {
  background-color: $white;
  border: 1px solid #9e9e9e;
  box-shadow: 0 2px 5px 0 #999;
  margin-bottom: 22px;

  h2 {
    height: 50px;
    color: #565656;
    line-height: 50px;
    padding: 0 0 0 10px;
  }

  div.input {
    margin-bottom: 0;
  }

  .materials_table {
    @extend .disconnected_borders_table;

    th {
      width: 135px;
      padding: 8px 25px;
    }

    td {
      &:nth-child(1) {
        width: 100px;
      }

      &:nth-child(2) {
        width: 110px;
        padding: 0;
      }

      &:nth-child(3),
      &:nth-child(4) {
        width: 95px;
      }

      &:nth-child(5) {
        width: 200px;
      }

      &:nth-child(6) {
        width: 100px;
      }
    }

    &.print_items {
      td {
        &:nth-child(1) {
          width: 290px;
        }

        &:nth-child(2) {
          width: 139px;
          padding: 3px;
        }

        &:nth-child(3) {
          width: 325px;
        }
      }
    }

    &.shopping_list_items {
      td {
        &:nth-child(1) {
          width: 160px;
        }

        &:nth-child(2) {
          width: 270px;
          padding: 0 10px;
          text-align: left;
        }

        &:nth-child(3) {
          width: 80px;
          padding: 0;
        }
        &:nth-child(4) {
          width: 120px;
          padding: 0;
        }

        &:nth-child(6) {
          width: 113px;
        }
      }
    }
  }

  .shared_questions_table {
    @extend .disconnected_borders_table;
    @include teacher_assessment_question;

    td {
      &:nth-child(1) {
        width: 50%;
      }

      &:nth-child(2) {
        width: 16%;
      }

      &:nth-child(3) {
        width: 16%;
      }

      &:nth-child(4) {
        width: 16%;
      }

      &:nth-child(5) {
        width: 16%;
      }
    }
  }

  table#shared-assessments, table#tci-assessments {
    @extend .disconnected_borders_table;

    td {
      padding: 0;
      width: 50px;

      &:nth-child(1) {
        text-align: left;
        padding-left: 20px;
        width: 300px;
      }
    }
  }

  input[type="text"] {
    margin-bottom: 0;
    height: 70px;
    box-shadow: inset 0 1px 3px 0 $med-grey;
  }
}

.spacer_row {
  &.top {
    height: 5px;

    td {
      border-top: 1px solid #dedede;
      padding: 0;
    }
  }

  &.bottom {
    height: 5px;

    td {
      padding: 0;
    }
  }
}

.teacher_email_table {
  width: 525px;
  background-color: $white;
  border: 1px solid #9e9e9e;
  box-shadow: 0 1px 1px 0 $med-grey;
  float: right;
  margin-top: 10px;

  td {
    width: 50%;
    padding: 0 10px;
    border-right: 1px solid #dedede;
    text-align: left;
    vertical-align: middle;
    line-height: 35px;

    span {
      font-size: 15px;
      color: #666;
    }

    &:last-child {
      border-right-style: none;
    }
  }

  thead {
    font-size: 20px;
    color: $very-dark-grey;
    background: #f5f5f5;
    line-height: 45px;
  }
}

.correlation-table {
  width: 100%;

  td {
    border: 1px solid black;
    padding: 5px;
  }

  .tag {
    width: 25%;
    font-size: 10px;
    line-height: 10px;
    overflow: hidden;
  }
}
