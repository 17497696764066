#correlations-modal-container {
  display: inline-block;

  .lesson-correlations {
    font-size: $font-size-base;

    img {
      vertical-align: middle;
      height: 2em;
    }
  }

  .modal_popup_wrapper { padding-bottom: 0; }
}

#correlations-modal {
  padding: 20px 30px;
  overflow-y: auto;

  p {
    &.SAEP_color { color: $ngss-blue; }
    &.CC_color   { color: $ngss-green; }
    &.DCI_color  { color: $ngss-orange; }

    font-size: 1em;
    margin-bottom: .75em;
    line-height: 1.1em;

    &.ngss-text {
      display: inline-block;
      margin-bottom: .5em;
    }

    &.ngss-sub-title {
      font-size: 1.1em;
      margin-bottom: .2em;
    }
  }

  ul {
    line-height: 1.2em;
    list-style: outside disc;
    margin-bottom: .5em;
    margin-left: 1.5em;
    font-size: 1em;

    li {
      margin-bottom: .5em;
      margin-left: 1%;
    }
  }

  .js_popup { font-size: 14px; }
}
