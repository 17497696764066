// purple theme

.tippy-tooltip.purple-theme {
  color: $white;
  background-color: $tci-purple;
  box-shadow: 0 4px 20px 4px rgba(0, 20, 60, .1), 0 4px 80px -8px rgba(0, 20, 60, .2);
}

.tippy-tooltip.dropdown-theme {
  min-width: 10rem;
  margin: 0.125rem 0 0;
  font-size: 0.875rem;
  text-align: left;
  list-style: none;
  padding: 0;
  background-color: $white;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.175);
  overflow: hidden;

  .tippy-content {
    padding: 0.5rem 0 0.5rem 0;
  }
}

// Used when animationFill: true. false by default
//.tippy-tooltip.purple-theme .tippy-backdrop {
//  background-color: $tci-purple;
//}

.tippy-popper[x-placement^=top] .tippy-tooltip.purple-theme .tippy-roundarrow {
  fill: $tci-purple;
}

.tippy-popper[x-placement^=top] .tippy-tooltip.purple-theme .tippy-arrow {
  border-top: 7px solid $tci-purple;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
}

.tippy-popper[x-placement^=top] .tippy-tooltip.purple-theme [x-arrow] {
  border-top: 7px solid $tci-purple;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
}

.tippy-popper[x-placement^=bottom] .tippy-tooltip.purple-theme .tippy-roundarrow {
  fill: $tci-purple;
}

.tippy-popper[x-placement^=bottom] .tippy-tooltip.purple-theme .tippy-arrow {
  border-bottom: 7px solid $tci-purple;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
}

.tippy-popper[x-placement^=bottom] .tippy-tooltip.purple-theme [x-arrow] {
  border-bottom: 7px solid $tci-purple;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
}

.tippy-popper[x-placement^=left] .tippy-tooltip.purple-theme .tippy-roundarrow {
  fill: $tci-purple;
}

.tippy-popper[x-placement^=left] .tippy-tooltip.purple-theme .tippy-arrow {
  border-left: 7px solid $tci-purple;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

.tippy-popper[x-placement^=left] .tippy-tooltip.purple-theme [x-arrow] {
  border-left: 7px solid $tci-purple;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

.tippy-popper[x-placement^=right] .tippy-tooltip.purple-theme .tippy-roundarrow {
  fill: $tci-purple;
}

.tippy-popper[x-placement^=right] .tippy-tooltip.purple-theme .tippy-arrow {
  border-right: 7px solid $tci-purple;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

.tippy-popper[x-placement^=right] .tippy-tooltip.purple-theme [x-arrow] {
  border-right: 7px solid $tci-purple;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}


// white theme
.tippy-tooltip.white-theme {
  color: $very-dark-grey;
  background-color: $white;
  box-shadow: 0 4px 20px 4px rgba(0, 20, 60, .1), 0 4px 80px -8px rgba(0, 20, 60, .2);
}

// Used when animationFill: true. false by default
//.tippy-tooltip.white-theme .tippy-backdrop {
//  background-color: $white;
//}

.tippy-popper[x-placement^=top] .tippy-tooltip.white-theme .tippy-roundarrow {
  fill: $white;
}

.tippy-popper[x-placement^=top] .tippy-tooltip.white-theme .tippy-arrow {
  border-top: 7px solid $white;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
}

.tippy-popper[x-placement^=top] .tippy-tooltip.white-theme [x-arrow] {
  border-top: 7px solid $white;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
}

.tippy-popper[x-placement^=bottom] .tippy-tooltip.white-theme .tippy-roundarrow {
  fill: $white;
}

.tippy-popper[x-placement^=bottom] .tippy-tooltip.white-theme .tippy-arrow {
  border-bottom: 7px solid $white;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
}

.tippy-popper[x-placement^=bottom] .tippy-tooltip.white-theme [x-arrow] {
  border-bottom: 7px solid $white;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
}

.tippy-popper[x-placement^=left] .tippy-tooltip.white-theme .tippy-roundarrow {
  fill: $white;
}

.tippy-popper[x-placement^=left] .tippy-tooltip.white-theme .tippy-arrow {
  border-left: 7px solid $white;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

.tippy-popper[x-placement^=left] .tippy-tooltip.white-theme [x-arrow] {
  border-left: 7px solid $white;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

.tippy-popper[x-placement^=right] .tippy-tooltip.white-theme .tippy-roundarrow {
  fill: $white;
}

.tippy-popper[x-placement^=right] .tippy-tooltip.white-theme .tippy-arrow {
  border-right: 7px solid $white;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

.tippy-popper[x-placement^=right] .tippy-tooltip.white-theme [x-arrow] {
  border-right: 7px solid $white;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
}

// tag alignment theme
.tippy-tooltip.tag-theme {
  text-align: left;
  font-size: 14px;
  width: 350px;
  padding: 10px 20px;
  color: #333;
}

// wide theme
.tippy-tooltip.wide-theme {
  width: 200px;
}
