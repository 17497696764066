// Spinner with a message
//
// Darkens the background and creates a box for content.
.spinner__overlay {
  display: none;
  cursor: default;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .7);
  z-index: $zindex-modal-backdrop;
}

.spinner__box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: $zindex-modal;

  background-color: white;
  padding: 1rem;
  border: 1px solid $light-grey;
  border-radius: $border-radius;
}

.spinner__message {
  padding-left: 1rem; // padding between message and spinner icon to the left
}
