//
// Start New Test cards
//
.new-test-card-deck {
  display: flex;
  justify-content: space-between;
}

// do not show underline for hyperlinked cards
.new-test-card-deck a:hover {
  text-decoration: none;
}

.new-test-card {
  width: 14.375rem;
  text-align: center;
}

.new-test-card--clickable {
  cursor: pointer;
}

.new-test-card__body {
  width: $card-width;
  height: 8.375rem;
  vertical-align: middle;
  display: table-cell;

  color: $body-color;
}

.new-test-card__body__img {
  max-width: 4.375rem;
  max-height: 3.75rem;
}

@each $color, $value in $theme-colors {
  .new-test-card__footer--#{$color} {
    color: color-yiq($value);
    background-color: $value;
    padding: .8125rem 0;
    border-radius: 0 0 $border-radius $border-radius;
  }
}


// Other Teachers' Tests table
.state-icon {
  @include bg-circle-filled($orange, 1.875rem);
}

