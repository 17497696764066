/*
  As we move stylesheets over, we will be missing mixins due to `bourbon` not being included through Webpack.
  We don't need Bourbon in our Webpack pipeline because we run Autoprefixer which takes care of all vendor prefixes.
  If any stylesheets using Bourbon mixins get moved over to Webpack, just define the mixin here as the original
  property and Autoprefixer will vendor prefix it for you.
*/

@mixin display($value) {
  display: $value;
}

@mixin justify-content($value) {
  justify-content: $value;
}

@mixin align-items($value) {
  align-items: $value;
}
