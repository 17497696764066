// Modal
//
// Default modal of 700px width, with a grey header and optional footer.
// The HTML structure is as follows:
//
// <div class="modal">
//   <div class="modal__overlay">
//     <div class="modal__dialog">
//       <div class="modal__header"></div>
//       <div class="modal__body"></div>
//       <div class="modal__footer"></div>
//     </div>
//   </div>
// </div>
//

// Dark overlay over rest of screen
.modal__overlay {
  position: fixed;
  z-index: $zindex-modal-backdrop;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, .75);
}

// Actual modal
.modal__dialog {
  z-index: $zindex-modal;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 700px;

  background: $white;
  border-radius: $border-radius;
  overflow: hidden;
}

.modal__header {
  background-color: $light-grey;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  line-height: 1.75rem;
  padding-bottom: 1rem;
}

.modal__footer {
  text-align: right;
  border-top: 1px solid #eee;
}

.modal__header,
.modal__body,
.modal__footer {
  padding: 1.25rem;
}


// Small and large modals, by width
.modal--sm {
  width: 500px;
}

.modal--lg {
  width: 900px;
}


// Modal close button
.close {
  float: right;
  font-size: 1.25rem;
  background-color: transparent;
  border: 0;
  padding: 0;
  color: #555;

  &:hover, &:focus {
    color: #000;
    text-decoration: none;
  }

  &:not(:disabled):not(.disabled) {
    cursor: pointer;
  }
}

button.close {
  padding: 0;
  background-color: transparent;
  border: 0;
  -webkit-appearance: none;
}


// Prevent scroll on body when modal is open
.modal-open {
  overflow: hidden;
}
