//
// Links
//

a {
  color: $link-color;
  text-decoration: $link-decoration;
  background-color: transparent; // Remove the gray background on active links in IE 10.
  -webkit-text-decoration-skip: objects; // Remove gaps in links underline in iOS 8+ and Safari 8+.

  &:hover,
  &.hover {
    color: $link-hover-color;
    text-decoration: $link-hover-decoration;
  }
}

// Undo these styles for placeholder links/named anchors (without href)
// which have not been made explicitly keyboard-focusable (without tabindex).

// Temporarily commented out so to better ensure backwards compatibility.
//a:not([href]):not([tabindex]) {
//  color: inherit;
//  text-decoration: none;
//
//  &:hover,
//  &.hover,
//  &:focus,
//  &.focus {
//    color: inherit;
//    text-decoration: none;
//  }
//
//  &:focus {
//    outline: 0;
//  }
//}
