table.dataTable {
  margin: 10px 0;
  position: relative;

  thead {
    ::after {
      font-family: FontAwesome, sans-serif;
      float: right;
    }

    .sorting::after {
      content: "\f0dc";
    }

    .sorting_desc::after {
      content: "\f0dd";
    }

    .sorting_asc::after {
      content: "\f0de";
    }

    .sorting_desc_disabled::after {
      content: "\f0dd";
      color: $very-dark-grey;
    }

    .sorting_asc_disabled::after {
      content: "\f0de";
      color: $very-dark-grey;
    }

    th:not(.sorting_disabled) {
      cursor: pointer;
    }
  }

  .dataTables_empty {
    text-align: center;
  }
}

.dataTables_wrapper {
  position: relative;
}

.dataTables_results {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.dataTables_info {
  margin-right: 15px;
}

.dataTables_length {
  label {
    overflow: visible;
  }
}

.dataTables_paginate {
  float: right;
  margin-top: 15px;
  font-size: 16px;

  .previous, .next {
    font-size: 0;
    visibility: hidden;
  }

  .previous::after {
    font-family: FontAwesome, sans-serif;
    visibility: visible;
    content: "\f053";
    font-size: 14px;
  }

  .next::after {
    font-family: FontAwesome, sans-serif;
    visibility: visible;
    content: "\f054";
    font-size: 14px;
  }

  .previous.disabled::after, .next.disabled::after {
    visibility: hidden;
  }

  .paginate_button {
    width: 30px;
    height: 30px;
    border-radius: $border-radius;
    background-color: $white;
    padding: 7.25px 9.5px;
    color: $dark-grey;

    &.current {
      background-color: $tci-purple;
      border: solid 1px $tci-purple;
      color: $white;
      cursor: not-allowed;
    }
  }
}
