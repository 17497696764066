.tablesorter {
  thead th {
    // keep background properties separate to not override initial table style
    background-repeat: no-repeat;
    background-position: right center;
  }

  .tablesorter-header {
    background-image: url(data:image/gif;base64,R0lGODlhFQAJAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAkAAAIXjI+AywnaYnhUMoqt3gZXPmVg94yJVQAAOw==);
    background-repeat: no-repeat;
    background-position: center right;
    padding-right: 1.25rem; // ensure space for arrow icons on sorted columns
    white-space: normal;
    cursor: pointer;
  }

  thead th.tablesorter-headerAsc {
    background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAQAAAINjI8Bya2wnINUMopZAQA7);
  }

  thead th.tablesorter-headerDesc {
    background-image: url(data:image/gif;base64,R0lGODlhFQAEAIAAACMtMP///yH5BAEAAAEALAAAAAAVAAQAAAINjB+gC+jP2ptn0WskLQA7);
  }

  thead .sorter-false {
    background-image: none;
    padding-right: inherit;
    cursor: default;
  }
}
