@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .reading-game-banner {
    height: 60px;
    background: url('../frontend/images/reading-game-banner.png') rgba(0, 85, 149, 0.8);
    background-blend-mode: multiply;
    background-size: cover;
  }
}

@layer utilities {
  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .text-sideways-lr {
    text-orientation: sideways;
    writing-mode: sideways-lr;
  }

  .text-sideways-rl {
    text-orientation: sideways;
    writing-mode: sideways-rl;
  }

  .shadow-soft {
    box-shadow: -2px 2px 10px 0px #0000004D;
  }
}
