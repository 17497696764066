.student-answer-card {
  overflow: hidden;
  width: 900px;
  margin: 0 auto 20px auto;

  .top-row {
    padding: 15px 20px;

    .row-data { display: flex; }
  }

  .name-container {
    font-weight: $font-weight-bold;
    flex-grow: 1;
  }

  .score-container {
    text-align: right;
  }

  .initials {
    display: inline-block;
    width: 33px;
    height: 33px;
    text-align: center;
    vertical-align: middle;
    line-height: 33px;
    border-radius: 22px;
    background-color: #f69c05;
    color: white;
    margin-right: 10px;
  }

  .divider {
    width: 100%;
    margin: 20px 0;
    border-bottom: 1px solid #eee;
  }

  .bottom-row {
    border-top: 1px solid #eee;
    padding: 20px;
    background-color: #fcfcfc;
  }
}

.answer-preview-table {
  width: 75%;
  margin: 0 auto;
  border: 1px solid $light-grey;
  white-space: normal;

  thead {
    background-color: $light-grey;
    font-weight: $font-weight-bold;
  }

  td {
    padding: 10px 15px;

    &.has-icon { width: 14px; }
  }

  tbody { background-color: $white; }

  tr:not(:last-child) td { border-bottom: 1px solid $light-grey; }

  .fa { font-size: 16px; }
  .fa-check-circle { color: $green; }
  .fa-times-circle { color: $red; }
}
