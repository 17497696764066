/* Styles ported over from application.css.scss */
@import 'base/mixins';
@import '~assets/stylesheets/forms';
@import '~assets/stylesheets/tables';

/* General UI Elements */

/* Expandable UI items */

.expand_all {
  @include action-link;
  cursor: pointer;
  margin-bottom: 5px;
}

// TODO: refactor out
.expandable {
  color: $tci-purple;
  cursor: pointer;
  background: image-url("expandable_buttons.png") no-repeat 8px 8px;
  border-top: 1px solid #e3e3e3;
  display: block;
  padding: 10px 10px 8px 40px;
  margin: 5px 0;
}

.expandable.open {
  background-position: 8px -31px;
}

.expandable.small {
  background-image: image-url("expandable_buttons_small.png");
}

.expandable.small.open {
  background-position: 8px -25px;
}

.expandable-btn {
  font-size: 20px;
  color: $white;
  background-color: $tci-purple;
  border-radius: 50%;
  border: 1px $white solid;
  padding: 0 6px 1px;
  box-shadow: $btn-box-shadow;
  cursor: pointer;

  &:focus {
    outline-color: $tci-purple;
  }
}

h3.show_hide {
  margin: 0;
  font-weight: normal;

  span {
    font-size: 16px;
  }
}

.correlation_information {
  list-style: none;
  padding-left: 45px;
}

p.question {
  color: $very-dark-grey;
  font-style: italic;
  font-weight: normal;
  text-shadow: 0 1px 0 $white;
}

.iframe_container {
  border: 1px solid $med-grey;
}

div.message {
  @include grad-box;
  margin: 30px auto;
  padding: 30px 50px 50px 50px;
  width: 580px;

  > p {
    font-size: 16px;
    line-height: 1.5em;
  }
}

.login_options {
  background: none repeat scroll 0 0 #fdfdfd;
  border: 1px solid $med-grey;
  font-size: 14px;
  line-height: 1.2em;
  margin: 20px 0;
  padding: 10px;
}

/* page-specific styling */

#grade_and_state {
  @include display(flex);
  @include align-items(center);
}

img.logo_in_list {
  height: 35px;
}

.swatch {
  height: 18px;
  width: 18px;
  border: 1px solid gray;
}

.other_sign_in {
  position: absolute;
  top: 10px;
  right: 12px;

  a {
    line-height: 2em;
    position: relative;
    top: -8px;
  }
}

.sections,
.procedures,
.lessons,
.program_resource,
.padded {
  li {
    line-height: 2em;
    padding: 5px;
  }

  &.has_buttons li a.action {
    line-height: 1em;
  }
}

.has_buttons li,
.sections li {
  border-bottom: 1px dotted $med-grey;
}

.sections li:last-child {
  border-bottom: none;
}

ul.tabs {
  text-align: left;
  margin: 1em 0 2px 0;
  padding: 3px 10px 0 10px;

  li {
    display: inline;
  }

  li.selected, a.selected, button.selected {
    border-bottom: 1px solid $white;
    background-color: $white;
  }

  li div.inner_tab_link {
    display: inline-block;
  }

  li a, li button, li div.inner_tab_link {
    font-family: $font-family-sans-serif;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    background-color: #e5e5e5;
    border: 1px solid;
    border-color: $med-grey #999 $med-grey $med-grey;
    color: #666;
    margin-right: 0;
    padding: 5px 10px 1px;
    text-decoration: none;
    cursor: pointer;
    font-size: 100%;
  }

  a:hover, div.inner_tab_link:hover {
    background: #f2f2f2;
  }

  li.selected a, li.selected button, li.selected div.inner_tab_link {
    background-color: $white;
    color: $black;
    border-bottom: 1px solid $white;
    cursor: pointer;
  }

  a.selected:hover, div.inner_tab_link.selected:hover {
    background-color: $white !important;
  }

  &.ie_fix {
    margin-bottom: -2px !important;
    padding-bottom: 0 !important;
  }
}

.tabs_container {
  padding: 10px 30px 40px;
  border: 1px solid;
  border-color: $med-grey #999 #999 $med-grey;
  min-height: 250px;
}

.tabs_container_wrapper {
  padding: 10px 20px;
  &.np { padding: 0; }
}

.pagination-container-right {
  float: right;

  .paginate-select-options {
    float: right;
  }

  .pagination {
    float: right;
  }

  .pagination-form select {
    margin-right: 10px;
    transform: translateY(-2px);
  }
}

.student_tabs {
  margin: 0 auto;
}

ul.buttons {
  margin: 0;
  padding: 0;

  li {
    float: left;
    display: block;
    margin-right: 5px;
  }

  a {
    float: left;
    display: block;
    padding: 8px 6px 3px 6px;
    font-size: 11px;
    height: 25px;
    background-image: image-url("bg-table-hdr.png");
    font-weight: bolder;
    text-align: center;
  }

  a:hover,
  a:active {
    background: #bbb;
    color: $black;
  }

  li.selected a, li.selected button {
    background-image: image-url("bg-tabs-dark.png");
    color: $white;
  }
}

.section_images,
.procedure_images {
  border: 1px solid $med-grey;
  margin: 20px 0;
}

ul.link_list li { line-height: 2.2em; }

.link_list li span.challenge,
.link_list li span.lesson_strategy, .link_list li span.reading_challenge {
  border-left: 1px solid $med-grey;
  float: right;
  font-size: 11px;
  margin-left: 40px;
  padding: 1px 10px 0 1px;
  width: 150px;
}

.link_list li span.small {
  font-size: 12px;
  color: $dark-grey;
}

.link_list li.link_list_header {
  position: relative;
  display: block;
  padding: 5px 8px 8px 8px;
  border: none;
  background: #f8f8f8;
  font-size: 11px;
  color: #666;
}

.link_list li.link_list_header span {
  border: medium none;
  color: #666;
  width: 150px;
  position: relative;
}

.link_list li a span.presentation_available {
  font-weight: $font-weight-bold;
  color: #333;
  margin-left: 50px;
}

.link_list.hover li {
  line-height: 1em;
  border-bottom: 1px dotted $med-grey;
}
.link_list.hover li:last-child { border-bottom: none; }

.link_list.hover a:link,
.link_list.hover a:visited {
  display: block;
  padding: 8px;
}

.link_list.hover a:hover,
.link_list.hover a:active { background-color: $light-grey; }
.link_list a span { color: #222; }
.link_list.hover.nb li { border-bottom: none; }

.link_list span.name {
  float: left;
  width: 150px;
  font-weight: $font-weight-bold;
}

.link_list.contacts {
  margin: 10px 0;
  border-top: 1px dotted $med-grey;
  font-size: 12px;
  margin-bottom: 20px;
}

.link_list.contacts li {
  border-bottom: 1px dotted $med-grey;
}

.info_list {
  font-size: 13px;
  color: $very-dark-grey;
  margin-bottom: 20px;

  span.info_title {
    font-weight: $font-weight-bold;
    color: #333;
  }
}

.info_list li {
  padding: 5px;
}

ul.library_questions, ul.programs_lesson {
  li {
    border: 2px solid $med-grey;
    padding: 10px;
    margin-bottom: 10px;

    @include teacher_assessment_question;
  }

  label {
    overflow: visible;
  }
}

.info_list.no_left_pad li {
  padding-left: 0;
  height: 20px;
  line-height: 20px;
  vertical-align: middle;
}

.info_list li span.bold_info {
  width: 100px;
  float: left;
  font-weight: $font-weight-bold;
}

span.small_notes {
  font-size: 11px;
  font-weight: normal;
  padding-left: 5px;
}

.info_list.actions li {
  line-height: 25px;
}

.info_list.actions li:hover {
  background: #f0f0f0;
}

div.shadow_box {
  .info_list {
    margin-bottom: 0;
  }
}

div.shadow_box.w750 {
  padding-bottom: 28px;
  background-image: image-url("bottom_shadow_750.png");
  background-repeat: no-repeat;
  background-position: bottom left;
}

div.shadow_box .content {
  padding: 20px;
  border: 1px solid #bbb;
}

.challenge_box {
  padding: 20px;
  border: 1px solid #ddd;
  position: relative;
  background: $white;
  border-radius: 4px;

  h2 {
    margin-top: 0;
    font-size: 18px;
  }

  p {
    margin-top: .5em;
    margin-bottom: 0;
    padding-top: .5em;
  }

  p + p {
    border-top: 1px dotted $med-grey;
  }
}

h1.light {
  color: $very-dark-grey;
}

.intro {
  font-size: 14px;
  color: $very-dark-grey;
  line-height: 1.5em;
}

.intro.small {
  font-size: 13px;
}

.small-caps {
  font-size: 11px;
  color: $very-dark-grey;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 2px;
  line-height: 1em;
}

#add_teacher {
  display: none;
}

#view_teacher_link {
  display: none;
}

#batch_upload_teacher,
#batch_upload_student,
#batch_update_student {
  display: none;
}

/* 404 and 500 Error Styles */
div.dialog {
  width: 450px;
  padding: 10px 20px;
  margin: 4em auto 0 auto;
  border: 3px solid $med-grey;
  text-align: center;
}

.dialog h1 {
  font-size: 120%;
  color: #494949;
  line-height: 1.2em;
  margin-bottom: 20px;
}

#social_media_container {
  float: right;

  a {
    display: inline-block;
    text-align: center;
    height: 25px;
    margin-left: 5px;
    margin-top: 5px;
    width: 25px;
  }

  .fa {
    font-size: 20px;
  }
}

.overlay_background {
  display: none;

  &.visible {
    position: fixed;
    z-index: $zindex-modal-backdrop;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .75);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.chosen-container-multi .chosen-container-single .chosen-choices li.search-choice .search-choice-close {
  background-image: image-url("chosen-sprite@2x.png") !important;
  background-position: -42px 1px !important;
  background-repeat: no-repeat !important;
  background-size: 52px 37px !important;
}

.show-hide-tags {
  @include display(flex);
  padding-top: 9px;

  span.cursor_point {
    cursor: pointer;
    color: $tci-purple;
  }

  .tags_label {
    width: 120px;
    color: $very-dark-grey;
    font-weight: $font-weight-bold;
  }

  .assessment_tags {
    width: 100px;
    color: $very-dark-grey;
    font-weight: $font-weight-bold;
  }
}
